<template>
  <div class="page" v-if="detailInfo">
    <van-nav-bar title="申请结案" left-arrow @click-left="onClickLeft">
    </van-nav-bar>
    <div class="content">
      <div class="sps">
        <div class="sp">
          <span class="l">
            业务编号：<span class="v">{{
              detailInfo.homeBasicDetailDTO.applyBizNo
            }}</span>
          </span>
          <span class="r">
            客户名称：<span class="v">{{
              detailInfo.homeBasicDetailDTO.customerName
            }}</span>
          </span>
        </div>
        <div class="sp">
          <span class="l">
            车牌号：<span class="v">{{
              detailInfo.homeBasicDetailDTO.vehiclePlateNo
            }}</span>
          </span>
          <span class="r">
            <span class="v">{{
              detailInfo.homeBasicDetailDTO.merchantRegion
            }}</span>
          </span>
        </div>
      </div>
      <div class="head">家访信息</div>
      <div class="form">
        <div>
          <div>
            <van-field
              readonly
              clickable
              required
              name="picker"
              :value="form.subject | enumMessage(subjectEnum)"
              label="家访对象"
              placeholder="家访对象"
              @click="
                !readonly && showSelect(form.subject, subjectEnum, 'subject')
              "
            />
            <van-field
              v-model.trim="form.customerName"
              :readonly="readonly"
              label="客户姓名"
              :disabled="unEditable"
              placeholder="客户姓名"
            />
            <van-field
              v-model.trim="form.mobile"
              :disabled="unEditable"
              :readonly="readonly"
              type="tel"
              maxlength="11"
              label="联系方式"
              placeholder="联系方式"
            />
            <van-field
              readonly
              clickable
              required
              name="picker"
              label="家访结果"
              placeholder="家访结果"
              :value="form.homeResult | enumMessage(resultList)"
              @click="
                !readonly &&
                  showSelect(form.homeResult, resultList, 'homeResult')
              "
            />
            <van-field
              readonly
              clickable
              required
              name="picker"
              label="是否回款"
              placeholder="是否回款"
              :value="form.hasCollection | enumMessage(yesNoEnum)"
              @click="
                !readonly &&
                  showSelect(form.hasCollection, yesNoEnum, 'hasCollection')
              "
            />

            <template v-if="form.hasCollection === 'Y'">
              <van-field
                required
                v-model="form.hasCollectionAmount"
                :readonly="readonly"
                type="number"
                label="金额"
                placeholder="金额"
              />
              <van-field
                required
                v-model.trim="form.payee"
                :readonly="readonly"
                label="回款人"
                placeholder="回款人"
              />
              <van-field
                readonly
                clickable
                required
                :value="form.payTime"
                label="回款时间"
                placeholder="回款时间"
                @click="!readonly && showDateSelect(form.payTime, 'payTime')"
              />
            </template>
          </div>
          <div class="divder"></div>
        </div>
      </div>

      <div class="head">信息核实</div>
      <div class="form">
        <div>
          <div class="addr-item">
            <van-field
              v-model="form.liveAddress"
              :readonly="true"
              type="textarea"
              rows="1"
              autosize
              label="居住地址"
              placeholder="居住地址"
            />
            <van-field label="是否有效" required>
              <template #input>
                <van-switch
                  :disabled="readonly"
                  v-model="form.liveEffectiveStatus"
                  size="20"
                  active-value="Y"
                  inactive-value="N"
                />
              </template>
            </van-field>
            <van-field
              readonly
              clickable
              required
              :value="form.liveVisitDate"
              label="外访日期"
              placeholder="外访日期"
              @click="
                !readonly && showDateSelect(form.liveVisitDate, 'liveVisitDate')
              "
            />

            <van-field
              required
              type="textarea"
              maxlength="200"
              :readonly="readonly"
              v-model="form.liveVisitSituation"
              label="走访情况"
              placeholder="走访情况"
            />
          </div>
          <div class="divder-big"></div>
          <div class="addr-item">
            <van-field
              v-model="form.companyAddress"
              :readonly="true"
              type="textarea"
              rows="1"
              autosize
              label="单位地址"
              placeholder="单位地址"
            />
            <van-field label="是否有效" required>
              <template #input>
                <van-switch
                  :disabled="readonly"
                  v-model="form.companyEffectiveStatus"
                  size="20"
                  active-value="Y"
                  inactive-value="N"
                />
              </template>
            </van-field>
            <van-field
              readonly
              clickable
              required
              :value="form.companyVisitDate"
              label="外访日期"
              placeholder="外访日期"
              @click="
                !readonly &&
                  showDateSelect(form.companyVisitDate, 'companyVisitDate')
              "
            />

            <van-field
              required
              type="textarea"
              maxlength="200"
              :readonly="readonly"
              v-model="form.companyVisitSituation"
              label="走访情况"
              placeholder="走访情况"
            />
          </div>
          <div class="divder-big"></div>
          <div class="addr-item">
            <van-field
              v-model="form.registerAddress"
              :readonly="true"
              type="textarea"
              rows="1"
              autosize
              label="户籍地址"
              placeholder="户籍地址"
            />
            <van-field label="是否有效" required>
              <template #input>
                <van-switch
                  :disabled="readonly"
                  v-model="form.registerEffectiveStatus"
                  size="20"
                  active-value="Y"
                  inactive-value="N"
                />
              </template>
            </van-field>
            <van-field
              readonly
              clickable
              required
              :value="form.registerVisitDate"
              label="外访日期"
              placeholder="外访日期"
              @click="
                !readonly &&
                  showDateSelect(form.registerVisitDate, 'registerVisitDate')
              "
            />

            <van-field
              required
              type="textarea"
              maxlength="200"
              :readonly="readonly"
              v-model="form.registerVisitSituation"
              label="走访情况"
              placeholder="走访情况"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="actions">
      <van-button v-if="readonly" type="info" block @click="handleSubmit"
        >下一页</van-button
      >
      <van-button v-else type="info" block @click="handleSubmit"
        >下一步</van-button
      >
    </div>

    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="selectInfo.columns"
        :default-index="selectInfo.defaultIndex"
        value-key="displayName"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
    <van-popup v-model="showDatePicker" position="bottom">
      <van-datetime-picker
        @confirm="onDateConfirm"
        @cancel="showDatePicker = false"
        v-model="selectDateInfo.value"
        type="date"
        title="选择回款时间"
      />
    </van-popup>
  </div>
</template>
<script>
import * as _ from "lodash";
import Api from "@/api/caseHandle";
import { Toast } from "vant";
import dayjs from "dayjs";
const DEFAULT_FORM = {
  assignNo: "",

  companyAddress: "",
  companyEffectiveStatus: "N",
  companyVisitDate: "",
  companyVisitSituation: "",

  customerName: "",
  feedbackPerson: "",
  feedbackTime: "",
  hasCollection: "",
  hasCollectionAmount: null,
  homeResult: "",

  liveAddress: "",
  liveEffectiveStatus: "N",
  liveVisitDate: "",
  liveVisitSituation: "",

  mobile: "",
  payTime: "",
  payee: "",

  registerAddress: "",
  registerEffectiveStatus: "N",
  registerVisitDate: "",
  registerVisitSituation: "",

  subject: "",
};

const subjectEnum = [
  {
    displayName: "本人",
    value: "本人",
  },
  {
    displayName: "家人",
    value: "家人",
  },
  {
    displayName: "朋友",
    value: "朋友",
  },
  {
    displayName: "同事",
    value: "同事",
  },
  {
    displayName: "其他",
    value: "其他",
  },
];
const yesNoEnum = [
  {
    displayName: "是",
    value: "Y",
  },
  {
    displayName: "否",
    value: "N",
  },
];
export default {
  data() {
    return {
      homeNo: this.$route.query.homeNo,
      readonly: !!this.$route.query.readonly,
      subjectEnum,
      yesNoEnum,
      resultList: [],
      detailInfo: null,
      showPicker: false,
      showDatePicker: false,
      areaList: {}, // 数据格式见 Area 组件文档

      form: {
        ..._.cloneDeep(DEFAULT_FORM),
      },

      applyInfo: null,

      selectInfo: {
        defaultIndex: 0,
        columns: [],
        key: "",
      },
      selectDateInfo: {
        value: null,
        key: "",
      },
    };
  },
  computed: {
    unEditable() {
      return this.form.subject === "本人";
    },
  },
  methods: {
    showSelect(value, enums, key) {
      const defaultIndex = Math.max(
        enums.map((e) => e.value).indexOf(value),
        0
      );
      this.selectInfo = {
        defaultIndex,
        columns: enums,
        key,
      };
      this.showPicker = true;
      this.key = key;
    },

    onConfirm(value) {
      if (this.key === "subject") {
        if (value.value === "本人") {
          // 家访为本人的时候，回显
          this.form.mobile = this.detailInfo.homeBasicDetailDTO.mobile;
          this.form.customerName =
            this.detailInfo.homeBasicDetailDTO.customerName;
        } else {
          this.form.mobile = "";
          this.form.customerName = "";
        }
      }
      const { selectInfo, form } = this;
      form[selectInfo.key] = value.value;
      this.showPicker = false;
      switch (form.hasCollection) {
        case "N":
          form.hasCollectionAmount = null;
          form.payee = "";
          form.payTime = "";
          break;
      }
    },

    showDateSelect(value, key) {
      this.selectDateInfo = {
        value: dayjs(value || dayjs().format("YYYY-MM-DD")).toDate(),
        key,
      };
      this.showDatePicker = true;
    },

    onDateConfirm(value) {
      const { selectDateInfo } = this;
      this.form[selectDateInfo.key] = dayjs(value).format("YYYY-MM-DD");
      this.showDatePicker = false;
    },

    async handleSubmit() {
      try {
        if (!this.readonly) {
          const loading = Toast.loading();
          const params = _.cloneDeep(this.form);
          if (!params.subject) {
            Toast(`请选择家访对象`);
            return;
          }
          // if (!params.customerName || params.customerName === "") {
          //   Toast(`请输入客户姓名`);
          //   return;
          // }
          if (!params.homeResult) {
            Toast(`请选择家访结果`);
            return;
          }
          if (!params.hasCollection || params.hasCollection == "") {
            Toast(`请选择是否回款`);
            return;
          }
          if (params.hasCollection == "Y") {
            if (params.hasCollectionAmount == null) {
              Toast(`请输入回款金额`);
              return;
            }
            if (params.payee == "") {
              Toast(`请输入回款人`);
              return;
            }
            if (params.payTime == "") {
              Toast(`请选择回款时间`);
              return;
            }
          }

          const {
            companyVisitDate,
            companyVisitSituation,

            liveVisitDate,
            liveVisitSituation,

            registerVisitDate,
            registerVisitSituation,
          } = params;

          if (!liveVisitDate) {
            Toast(`请选择居住地址外访日期`);
            return;
          }
          if (!liveVisitSituation) {
            Toast(`请输入居住地址走访情况`);
            return;
          }

          if (!companyVisitDate) {
            Toast(`请选择单位地址外访日期`);
            return;
          }
          if (!companyVisitSituation) {
            Toast(`请输入单位地址走访情况`);
            return;
          }

          if (!registerVisitDate) {
            Toast(`请选择户籍地址外访日期`);
            return;
          }
          if (!registerVisitSituation) {
            Toast(`请输入户籍地址走访情况`);
            return;
          }

          const { detailInfo, applyInfo } = this;
          const { homeBasicDetailDTO } = detailInfo;

          Object.assign(params, {
            assignNo: detailInfo.assignNo,
            submitOverdueDays: homeBasicDetailDTO.currentOverdueDays,
            submitOverdueAmount: homeBasicDetailDTO.currentOverdueAmount,
            customerName: params.customerName || " ",
            mobile: params.mobile || " ",
          });

          let res = null;
          if (applyInfo.subject) {
            res = await Api.updateFinishApply(params);
          } else {
            res = await Api.addFinishApply(params);
          }

          loading.close();
          Toast.success(res.message);
        }

        this.$router.push({
          name: "Settle2",
          query: this.$route.query,
        });
      } catch (error) {
        Toast.fail(error);
        throw error;
      }
    },

    // 加载详情
    async loadDetail() {
      const loading = Toast.loading();
      try {
        const res = await Api.findDetailByHomeNo(this.homeNo);
        this.detailInfo = res.data;
        await this.getFinishApply(this.detailInfo.assignNo);
        loading.close();
      } catch (error) {
        Toast.fail(error);
      }
    },

    // 结案详情
    async getFinishApply(assignNo) {
      try {
        const res = await Api.getFinishApply(assignNo);
        const { data } = res;
        const { homeBasicDetailDTO } = this.detailInfo;

        Object.assign(this.form, data, {
          companyAddress: homeBasicDetailDTO.workAddress || "",
          liveAddress: homeBasicDetailDTO.address || "",
          registerAddress: homeBasicDetailDTO.permanentzAddress || "",
        });
        this.applyInfo = data;
      } catch (error) {
        Toast.fail(error);
      }
    },

    // 家访结果
    async loadResultConfigList() {
      try {
        const res = await Api.homeResultConfigList();
        this.resultList = (res.data || []).map((item) => {
          return {
            ...item,
            displayName: item.homeResult,
            value: item.homeResult,
          };
        });
      } catch (error) {
        Toast.fail(error);
      }
    },
  },
  mounted() {
    this.loadDetail();
    this.loadResultConfigList();
  },
};
</script>
<style lang="less" scoped>
@import "../../assets/css/variables.less";
.page {
  height: 100%;
  background: @pageBg;
  display: flex;
  flex-direction: column;
  .content {
    flex: 1;
    overflow-y: auto;

    .sps {
      margin: 5px 5px;
      background: #fff;

      .sp {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 8px 8px;

        .v {
          font-weight: bold;
        }
      }
    }

    .head {
      background: #fff;
      padding: 10px 10px;
      margin-bottom: 2px;
      font-size: 14px;
      font-weight: bold;
    }

    .form {
      margin-bottom: 10px;
    }

    .divder-big {
      height: 10px;
      background: #f2f2f2;
    }

    .divder {
      height: 2px;
      background: #f2f2f2;
    }
  }

  .actions {
    .van-button {
      border-radius: 0px;
    }
  }
}
</style>